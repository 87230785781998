import logo from './logo.svg';
import './App.css';
import AccountDeletion from './Components/AccountDeletion/AccountDeletion';
import OtpScreen from './Components/AccountDeletion/OtpScreen';


function App() {
  return (
    <div>
      <AccountDeletion/>
      {/* <OtpScreen/> */}
    </div>
  );
}

export default App;
