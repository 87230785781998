import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './AccountDeletion.css';
import OtpInput from "otp-input-react";
import { CgSpinner } from 'react-icons/cg'
import logo from '../Assets/logo.png';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';

const AccountDeletion = () => {

  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState('');

  const submitPhone = (event) => {
    setLoading(true);
    // setShowOTP(true);
    let mobileNumber = phoneNumber.substring(2);
    if (mobileNumber == null || mobileNumber == '') {
      setError('Enter mobile number');
      setLoading(false);

    }
    else if (mobileNumber.length != 10) {
      setError('Enter valid Number');
      setLoading(false);

    } else {
      setError('');
      console.log(mobileNumber);
      axios.patch('https://test-api.parkit.biz/v1/user/deactivate-account', { mobileNumber }).then(response => {
        setLoading(false);
        setStatus('success');
        console.log(response.data);
      })
        .catch(error => {
          setError(error.response.data.message);
          setLoading(false);
          console.error('Error deleting data:', error.response.data.message);
        });
    }
    event.preventDefault();
  };

  const submitOTP = () => {
    setLoading(true);
    setStatus('success');
  };

  return (
    <div className="container">
      <header className="text-center my-4i">
        <img width="70" src={logo} alt='ParkitLogo' />
      </header>
      {
        status ?
          <h3 className="text-center">Request Submitted</h3>
          :
          <div>
            <h4 className="text-center">Account Deletion</h4>


            {
              showOTP ?
                <>
                  <div className="row justify-content-center">
                    <form>
                      <div className="form-group phone-field">
                        <label htmlFor="phone">Enter OTP sent to your mobile number: </label>
                        <br />
                        <OtpInput value={otp} onChange={setOtp} OTPLength={6} otptype="number" disabled={false} autoFocus className='d-flex items-center justify-content-center'></OtpInput>

                      </div>
                      <button className='button' onClick={submitOTP}>
                        {
                          loading &&
                          <CgSpinner size={20} className='animate-spin' />

                        }
                        <span>Verify OTP</span>
                      </button>
                    </form>
                  </div>
                </> : <>
                  <div className="row justify-content-center">
                    <form>
                      <div className="form-group phone-field">
                        <label htmlFor="phone">Enter your registered mobile number:</label>
                        <br />
                        {error && <div className="alert alert-danger w-100">{error}</div>}
                        <PhoneInput country={'in'} placeholder="+919321693838" id="phone" name="phone" value={phoneNumber} onChange={setPhoneNumber} className="form-control" />
                        {/* <input type="number" id="phone" name="phone" value={phoneNumber} onChange={setPhoneNumber} className="form-control" placeholder="9321693838" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" /> */}
                        {/* {!isValidPhone && <span className="error-message">Please enter a valid phone number.</span>} */}
                      </div>
                      {
                          loading &&
                          <CgSpinner size={20} className='animate-spin' />

                        }
                      <input type="submit" onClick={submitPhone} className="button" />
                    </form>
                  </div>
                </>
            }

          </div>
      }


    </div>
  );
};

export default AccountDeletion;
